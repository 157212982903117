import { computed } from '@vue/composition-api';
import { useRouteData } from './route-data';

export const componentAliases = {
  dc: () => import('@/modules/linked-objects/ui/linked-object-card'),
  oc: () => import('@/modules/objects/ui/object-card/ObjectCard.vue'),
  lc: () => import('@/modules/landmarks/ui/landmark-card/LandmarkCard.vue'),
  gc: () => import('@/modules/geozones/ui/geozone-card/GeozoneCard.vue'),
  hc: () => import('@/modules/notifications/ui/HistoryNotificationList.vue'),
  hec: () => import('@/modules/property-history/ui/HistoryPropertyList.vue'),
  hcs: () => import('@/modules/controls-history/ui/HistoryPropertyList.vue'),
  gt: () => import('@/modules/geotags/ui/geotag-card/GeotagCard.vue'),
  tc: () => import('@/modules/object-types/ui/type-card/TypeCard.vue')
};

const getAlias = component => {
  return (
    Object.keys(componentAliases).find(
      key => componentAliases[key] === component
    ) || null
  );
};

export default function(defaultView = { component: {}, props: {} }) {
  const { params, query, path } = useRouteData();

  const stack = computed(() => {
    return query.value.st || [];
  });

  const currentView = computed(() => {
    if (stack.value.length === 0) {
      return defaultView;
    }

    const lastItem = stack.value[stack.value.length - 1];
    if (!componentAliases[lastItem.c]) {
      return defaultView;
    }

    return {
      component: componentAliases[lastItem.c],
      props: lastItem.p || {}
    };
  });

  const getNextRoute = ({ component, props = {} }) => {
    const alias = getAlias(component);

    if (!alias) {
      console.warn('unknown component for stack');
      return {
        path: path.value,
        params: params.value,
        query: query.value
      };
    }

    const newStack = [
      ...stack.value,
      {
        c: alias,
        p: props
      }
    ];

    return {
      path: path.value,
      params: params.value,
      query: {
        ...query.value,
        st: newStack
      }
    };
  };

  const getPrevRoute = () => {
    const { st, ...newQuery } = query.value;
    const newSt = st?.slice(0, -1);

    return {
      path: path.value,
      params: params.value,
      query: {
        ...newQuery,
        st: newSt
      }
    };
  };

  const hasPrevRoute = computed(() => stack.value.length > 0);

  const stackKey = computed(() => JSON.stringify(stack.value));

  return {
    getNextRoute,
    getPrevRoute,
    currentView,
    hasPrevRoute,
    stackKey
  };
}
