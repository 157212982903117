import router from '@/router';
import { reactive, toRefs } from '@vue/composition-api';

const routerData = reactive({
  params: router.currentRoute.params,
  query: router.currentRoute.query,
  path: router.currentRoute.path
});
router.afterEach(route => {
  routerData.params = route.params;
  routerData.query = route.query;
  routerData.path = route.path;
});

export function useRouteData() {
  return toRefs(routerData);
}
